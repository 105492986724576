import styles from './NewsletterForm.module.scss';
import { useState } from 'react';
import userMessages from './MailxpertUserMessages';

export default () => {
  const [state, setState] = useState('');
  const [message, setMessage] = useState('');

  /** Submit subscription form */
  const submit = (event) => {
    event.preventDefault();
    setState('sending');
    setMessage(userMessages.sending);
    const email = event.target[0].value;
    const token = process.env.REACT_APP_MAILXPERT_API_TOKEN;
    fetch(`https://api.mailxpert.ch/v2.0/contacts`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
        contact_list_id: process.env.REACT_APP_MAILXPERT_LIST_ID,
      }),
    }).then((response) => {
      response.json().then((data) => {
        // Get custom message if available.
        // Note: we have to use the message itself as key (s. ./MailxpertUserMessages.js)
        const userMessage = response.status === 201 ? userMessages.success : userMessages[data.errors[0].message];
        // Display custom message or error message received from API
        setMessage(userMessage || data.errors[0].message);
        setState(response.status === 201 ? 'success' : 'error');
      });
    });
  };

  /** Message template */
  const messageTmpl = () => (
    <div className={`${styles.message} ${state === 'error' ? styles['message--error'] : ''}`}>{message}</div>
  );

  /** Render template */
  return (
    <>
      <form onSubmit={submit}>
        <div className={styles.group}>
          <input
            type="email"
            required
            className={`${styles.input} ${styles['input--email']}`}
            placeholder="lotte@home.ch"
          />
          <input type="submit" className={`${styles.input} ${styles['input--submit']}`} value="Anmelden" />
        </div>
      </form>
      {state ? messageTmpl() : null}
    </>
  );
};
