/**
 * Custom user messages for mailXpert messages. As mailXpert does not provide translated messages, a unique message key
 * or code, we have to use the message itself as key.
 *
 * s. conversation with Simon Meier (mailXpert support via mail on 2025-02-11, ticket reference #29258)
 */
export default {
  'This value is not a valid email address.': 'Die E-Mail-Adresse ist ungültig.',
  'A contact with this email already exists in this contact list.': 'Diese E-Mail-Adresse ist bereits angemeldet.',
  success: 'Besten Dank für die Anmeldung!',
  sending: 'Einen Moment bitte…',
};
